<template>
  <v-container>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Technology Search</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <TechForm @panel="panel = null" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col v-if="$store.getters['technologies']">
        <v-card :elevation="0">
          <v-card-title><h3>Technology Catalog</h3></v-card-title>
          <TechTable />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TechForm from "./Form";
import TechTable from "./Table";

export default {
  // eslint-disable-next-line
  name: "Catalogue",
  components: {
    TechForm,
    TechTable,
  },
  methods: {},
  data: () => ({
    panel: 0,
  }),
};
</script>

<style scoped>
::v-deep .v-icon {
  color: #07519e !important;
}
</style>
