var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-data-table',{attrs:{"show-expand":"","item-key":"key","single-expand":true,"headers":[
          {
            text: 'Technology',
            value: 'details.name',
          },
          {
            text: 'Provider',
            value: 'providers.company',
          },
          {
            text: 'Website',
            value: 'providers.website',
          },
          {
            text: 'Phone',
            value: 'providers.phone',
          },
          {
            text: 'Email',
            value: 'providers.email',
          },
          {
            text: 'Contact Form',
            value: 'providers.contact_form',
          },
        ],"items-per-page":10,"items":_vm.items()},on:{"click:row":_vm.details},scopedSlots:_vm._u([{key:`item.providers.website`,fn:function({ item }){return [(item.providers.website)?_c('v-btn',{attrs:{"icon":"","href":item.providers.website,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_vm._e()]}},{key:`item.providers.contact_form`,fn:function({ item }){return [(item.providers.contact_form)?_c('v-btn',{attrs:{"icon":"","href":item.providers.contact_form,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_vm._e()]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticStyle:{"padding":"0"},attrs:{"colspan":headers.length}},[_c('TableExtension',{staticStyle:{"min-width":"325px !important"},attrs:{"item":item}})],1),_c('br')]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }