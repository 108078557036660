<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <v-data-table
          show-expand
          item-key="key"
          :single-expand="true"
          :headers="[
            {
              text: 'Technology',
              value: 'details.name',
            },
            {
              text: 'Provider',
              value: 'providers.company',
            },
            {
              text: 'Website',
              value: 'providers.website',
            },
            {
              text: 'Phone',
              value: 'providers.phone',
            },
            {
              text: 'Email',
              value: 'providers.email',
            },
            {
              text: 'Contact Form',
              value: 'providers.contact_form',
            },
          ]"
          :items-per-page="10"
          :items="items()"
          @click:row="details"
        >
          <template v-slot:[`item.providers.website`]="{ item }">
            <v-btn
              v-if="item.providers.website"
              icon
              :href="item.providers.website"
              target="_blank"
              ><v-icon>mdi-open-in-new</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.providers.contact_form`]="{ item }">
            <v-btn
              v-if="item.providers.contact_form"
              icon
              :href="item.providers.contact_form"
              target="_blank"
              ><v-icon>mdi-open-in-new</v-icon></v-btn
            >
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 0">
              <TableExtension
                :item="item"
                style="min-width: 325px !important"
              />
            </td>
            <br />
          </template>
        </v-data-table> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import TableExtension from "./table_components/TableExtension";

export default {
  // eslint-disable-next-line
  name: "TechTable",
  components: {
    TableExtension,
  },
  data: () => ({
    expanded: [],
  }),
  methods: {
    details(row) {
      this.expanded = row === this.expanded[0] ? [] : [row];
    },
    items() {
      let items = this.$store.getters["technologies"];
      items.forEach((item, i) => {
        item.key = i;
      });
      return items;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  max-width: 325px !important;
}
</style>
