<template>
  <v-container>
    <v-row>
      <v-col style="padding: 0.75rem 0"
        ><p>
          Search for hydropower technologies by filling out the form:
        </p></v-col
      >
    </v-row>
    <v-form ref="form">
      <v-row id="form">
        <v-col style="border-right: 1px solid black">
          <v-row> <b>1. Select at least one technology Application</b> </v-row>
          <br /><br />
          <v-row><h5>Note:</h5> </v-row>
          <v-row>
            <div style="color: red; display: inline">*</div>
            <p style="font-size: 0.75rem">This is a required field</p>
          </v-row>
          <v-row>
            <v-col style="padding: 1.5rem; min-width: 215px">
              <v-row
                ><h4>
                  Application
                  <div style="color: red; display: inline">*</div>
                </h4></v-row
              >
              <v-row style="padding-bottom: 1rem"
                ><v-select
                  v-model="$store.getters['form'].application"
                  :items="applications"
                  :rules="[...rules.required]"
                  label="Select all that apply"
                  multiple
                  chips
                  item-color="#07519E"
                  hint="Application"
                  persistent-hint
                  color="#07519E"
                ></v-select
              ></v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col style="border-right: 1px solid black">
          <v-row> <b>2. Select a technology Type</b> </v-row>
          <br /><br />
          <v-row><h5>Definitions:</h5></v-row>
          <v-row>
            <v-col cols="auto">
              <v-row style="align-items: center">
                Hydrokinetic
                <v-tooltip right max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-help-circle-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Kinetic energy turbines, also called free-flow turbines,
                    generate electricity from the kinetic energy present in
                    flowing water rather than the potential energy from the
                    head. The systems can operate in rivers, man-made channels,
                    tidal waters, or ocean currents. Because kinetic systems
                    utilize a water stream's natural pathway, they do not
                    require diversion of water through man-made channels,
                    riverbeds, or pipes, although they might have applications
                    in such conduits. Kinetic systems do not require large civil
                    works because they can use existing structures, such as
                    bridges, tailraces, and channels.
                  </span>
                </v-tooltip>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row style="align-items: center">
                Impulse
                <v-tooltip right max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-help-circle-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    An impulse turbine generally uses the velocity of the water
                    to move the runner and discharges at atmospheric pressure. A
                    water stream hits each bucket on the runner. With no suction
                    on the down side of the turbine, the water flows out the
                    bottom of the turbine housing after hitting the runner. An
                    impulse turbine is generally suitable for high-head,
                    low-flow applications. The two main types of impulse turbine
                    are Pelton and cross-flow turbines.
                  </span>
                </v-tooltip>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row style="align-items: center">
                Reaction
                <v-tooltip right max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-help-circle-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    A reaction turbine generates power from the combined forces
                    of pressure and moving water. A runner is placed directly in
                    the water stream, allowing water to flow over the blades
                    rather than striking each individually. Reaction turbines
                    are generally used for sites with lower head and higher
                    flows and are the most common type currently used in the
                    United States. The two most common types of reaction
                    turbines are Propeller and Francis. Additional types include
                    Kaplan, Bulb, Straflo, and Tube.
                  </span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="padding-top: 1rem">
            <h4>Type</h4>
          </v-row>
          <v-row
            ><v-col style="min-width: 175px">
              <v-radio-group v-model="$store.getters['form'].type">
                <v-radio
                  v-for="type in types"
                  :key="type"
                  :label="type"
                  :value="type"
                  color="#07519E"
                ></v-radio>
              </v-radio-group> </v-col
          ></v-row>
        </v-col>
        <v-col>
          <v-row>
            <b>3. Enter the desired technology Operational Parameters</b>
          </v-row>
          <br /><br />
          <v-row><h5>Note:</h5></v-row>
          <v-row
            ><p style="font-size: 0.75rem">
              1. The database will return any technology that matches at least
              <i>one</i> parameter range.
              <br />
              2. If an operational parameter is not yet known, it is included in
              the query result.
            </p></v-row
          >
          <v-row>
            <v-col>
              <h4>Power Rating (kW)</h4>
              <v-text-field
                style="min-width: 175px"
                type="number"
                height="25"
                label="Minimum Power Rating"
                hint="kW"
                color="#07519E"
                v-model="$store.getters['form'].power_rating_min"
              >
              </v-text-field>
              <v-text-field
                style="min-width: 175px"
                type="number"
                height="25"
                label="Maximum Power Rating"
                hint="kW"
                color="#07519E"
                v-model="$store.getters['form'].power_rating_max"
              >
              </v-text-field>

              <template v-if="$store.getters['form'].type == 'Hydrokinetic'">
                <h4>Water Depth (ft)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Depth"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Depth"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_max"
                >
                </v-text-field>
              </template>
              <template v-else>
                <h4>Head (ft)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Head"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Head"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_max"
                >
                </v-text-field>
              </template>
            </v-col>

            <v-col>
              <template v-if="$store.getters['form'].type == 'Hydrokinetic'">
                <h4>Flow Rate (ft/s)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Flow Rate"
                  hint="ft/s"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Flow Rate"
                  hint="ft/s"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_max"
                >
                </v-text-field>
              </template>
              <template v-else>
                <h4>Flow (cfs)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Flow"
                  hint="cfs"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Flow"
                  hint="cfs"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_max"
                >
                </v-text-field>
              </template>

              <h4>Voltage (V)</h4>
              <v-text-field
                style="min-width: 175px"
                type="number"
                height="25"
                label="Voltage"
                hint="Volts"
                color="#07519E"
                v-model="$store.getters['form'].voltage"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="border-bottom: 1px solid black">
        <v-col class="text-right"
          ><v-btn @click="search()">Search</v-btn></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="aligned"
                >Export</v-expansion-panel-header
              >
              <v-expansion-panel-content
                ><v-row
                  ><v-col>
                    <v-tooltip left max-width="250px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          @click="xls('query')"
                          >Export Query</v-btn
                        >
                      </template>
                      <span
                        >Export technology matching the provided search
                        parameters</span
                      ></v-tooltip
                    >
                  </v-col></v-row
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="error">
      <v-col>
        {{ error }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import FileSaver from "file-saver";

export default {
  // eslint-disable-next-line
  name: "TechForm",
  data: () => ({
    rules: {
      required: [(v) => !!v || "Required"],
    },
    types: [],
    applications: [],
    error: null,
  }),
  methods: {
    async search() {
      if (this.$refs.form.validate()) {
        await axios
          .post(
            "django/tech/catalogue",
            JSON.stringify(this.$store.getters["form"])
          )
          .then((response) => {
            this.$emit("panel");
            this.$store.commit("technologies", response.data.technologies);
          })
          .catch((error) => {
            this.error = error.response.data.message;
          });
      }
    },
    async xls(query_type) {
      if (query_type === "query" && this.$refs.form.validate()) {
        await axios
          .post(
            `django/tech/xls`,
            JSON.stringify(this.$store.getters["form"]),
            { responseType: "blob" }
          )
          .then((response) => {
            FileSaver.saveAs(response.data, "hydro-technology-query");
          });
      }
    },
  },
  mounted: async function () {
    this.types = await axios
      .get("django/tech/types")
      .then((response) => {
        return response.data.types;
      })
      .catch((error) => {
        console.log(error);
      });
    this.applications = await axios
      .get("django/tech/applications")
      .then((response) => {
        return response.data.applications;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
#form > .col {
  padding: 1rem 2rem;
}

.v-expansion-panels {
  justify-content: end;
}

::v-deep .v-icon {
  color: #07519e !important;
}

.v-expansion-panel {
  max-width: 185px !important;
}
</style>
